"use client";

// imports
import { HIDE_URGENT_MESSAGE } from "@/lib/constants";
import { useHasMounted } from "@/lib/hooks";
import { useState } from "react";

// lib files
import { canAccessStorage } from "@/lib/helpers/is-storage-available";
import { ContentServiceMessageObject } from "@/lib/types/api/content-service";

// components
import LinkButton from "../Button/LinkButton";

// svgs
import CloseIcon from '/public/svg/close.svg';

// styles
import styles from './UrgentMessageBadge.module.scss';
interface UrgentMessageBadgeProps {
  color?: "teal" | "yellow" | "red";
  messageData: ContentServiceMessageObject;
}
const isUrgentMessageHidden = () => {
  if (canAccessStorage('sessionStorage')) {
    return sessionStorage.getItem(HIDE_URGENT_MESSAGE) === 'true';
  }
  return false;
};
const UrgentMessageBadge = (props: UrgentMessageBadgeProps) => {
  const hasMounted = useHasMounted();
  const [hidden, setHidden] = useState(() => isUrgentMessageHidden());

  // Checks if the component is mounted on the client
  // or if it's hidden
  if (!hasMounted || hidden) {
    return null;
  }
  const {
    color = "red",
    messageData
  } = props;
  const {
    headerText,
    descriptionText,
    urlButtonText,
    urlLink,
    webMessageBackgroundColor,
    webMessageTextColor,
    webMessageHeightPercent
  } = messageData;
  const hideBadge = () => {
    if (canAccessStorage('sessionStorage')) {
      sessionStorage.setItem(HIDE_URGENT_MESSAGE, 'true');
    }
    setHidden(true);
  };
  const stylesObj: React.CSSProperties = {};
  let wrapperClasses = styles.urgent_message_badge_wrapper;
  if (webMessageBackgroundColor) {
    stylesObj.backgroundColor = webMessageBackgroundColor;
  } else {
    // if there is no color specified by the messages response,
    // fall back to the color prop
    // which have special classes
    wrapperClasses += ` ${styles[color]}`;
  }
  if (webMessageTextColor) {
    stylesObj.color = webMessageTextColor;
  }
  if (webMessageHeightPercent && webMessageHeightPercent >= 50) {
    stylesObj.height = `${webMessageHeightPercent}vh`;
  }
  return <div className={wrapperClasses} style={stylesObj} data-sentry-component="UrgentMessageBadge" data-sentry-source-file="UrgentMessageBadge.tsx">
      <div className={styles.urgent_message_badge}>
        <button className={styles.urgent_message_badge__close_button} onClick={hideBadge} aria-label='Close'>
          <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="UrgentMessageBadge.tsx" />
        </button>
        <h3 className={styles.urgent_message_badge__headline}>
          {headerText}
        </h3>
        <p className={styles.urgent_message_badge__description}>
          {descriptionText}
        </p>
        <LinkButton className={styles.urgent_message_badge__button} href={urlLink} title={urlButtonText} style="white" target="_blank" data-sentry-element="LinkButton" data-sentry-source-file="UrgentMessageBadge.tsx">
          {urlButtonText}
        </LinkButton>
      </div>
    </div>;
};
export default UrgentMessageBadge;