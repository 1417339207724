import safeFetch from '@/lib/helpers/safe-fetch'

export async function addFavoriteVideo(video_cid: string) {
  return await put(`/api/profile/my-list-videos/${video_cid}/`);
}

export async function removeFavoriteVideo(video_cid: string) {
  return await httpDelete(`/api/profile/my-list-videos/${video_cid}/`);
}

export async function addFavoriteShow(show_cid: string) {
  return await put(`/api/profile/my-list-shows/${show_cid}/`);
}

export async function removeFavoriteShow(show_cid: string) {
  return await httpDelete(`/api/profile/my-list-shows/${show_cid}/`);
}

export async function removeWatchedVideo(video_cid: string) {
  return await httpDelete(`/api/profile/viewing-history/${video_cid}/`);
}

async function put(url: string) {
  return await safeFetch(url, { method: 'PUT', credentials: 'include'})
}

async function httpDelete(url: string) {
  return await safeFetch(url, { method: 'DELETE', credentials: 'include'})
}

